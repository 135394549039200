<template>
  <div>
    <form novalidate @submit.prevent="validateUser">
      <div class="md-layout">
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <md-card >
            <md-card-header>
              <md-card-header-text>
                <div class="md-title" v-if="instance.id">Editando Cuenta</div>
                <div class="md-title" v-if="!instance.id">Cuentas Contables</div>
              </md-card-header-text>
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button class="md-icon-button" @click="go_to('/detalle_ventas')">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button v-if="!sending" class="md-icon-button" @click="refreshNode">
                  <md-icon>refresh</md-icon>
                </md-button>
                <md-progress-spinner v-if="sending" class="md-primary"
                :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              </md-menu>
            </md-card-header>
            <md-card-content>
              <div>
                <v-jstree :data="asyncData" :async="loadData" ref="tree" whole-row
                  @item-click="itemClick">
                    <template scope="_">
                      <div style="display: inherit; width: 200px"
                      @click.ctrl="customItemClickWithCtrl">
                         <i :class="_.vm.themeIconClasses"
                         role="presentation" v-if="!_.model.loading"></i>
                        {{_.model.text}}
                     </div>
                  </template>
                </v-jstree>
              </div>
            </md-card-content>
          </md-card>
          <br>
          <br>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100"
        style="position: sticky; top: 0;">
          <md-card>
            <md-card-header>
              <md-card-header-text>
                <div class="md-title">
                  <span v-for="(item, key) in list_parents" :key="key">{{item}} -> </span>
                   <span v-if="instance.name"> {{instance.name}}</span>
                </div>
              </md-card-header-text>
            </md-card-header>
            <md-card-content>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field :class="getValidationClass('name')">
                    <label for="name">Descripción Cuenta</label>
                    <md-input type="text"
                    ref="descripcion" v-model="instance.name" :disabled="sending" />
                    <span class="md-error"
                    v-if="!$v.instance.name.required">Descripción de la cuenta es requerida</span>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100">
                  <md-field >
                    <label for="nomenclature">Nomeclatura</label>
                    <md-input type="text" v-model="instance.nomenclature" :disabled="sending" />
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100">
                  <br>
                  <md-switch v-model="instance.cumulative">
                    <span v-if="instance.cumulative">ACUMULATIVA</span>
                    <span v-if="!instance.cumulative">DETALLE</span>
                  </md-switch>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-autocomplete autocomplete="off" class="autocomplete"
                    :disabled="sending"
                    v-model="instance.account_name"
                    md-input-name="account"
                    :md-options="list_account"
                    @md-selected="set_account"
                    @md-changed="get_list_account" >
                  <label for="account">Cuenta bancaria</label>
                  <template
                    slot="md-autocomplete-item" slot-scope="{ item, term }">
                    <md-highlight-text :md-term="term">
                      {{ item.name }} ({{ item.account_number }}) {{ item.bank_name }}
                    </md-highlight-text>
                  </template>
                  </md-autocomplete>
                </div>
              </div>
            </md-card-content>
            <md-progress-bar md-mode="indeterminate" v-if="sending" />
            <md-card-actions md-alignment="space-between">
              <md-button
              type="submit"
              class="md-primary" :disabled="sending">Guardar cambios</md-button>
              <md-button v-if="instanceForm.id" @click.prevent="showDialog_form=true"
              class="md-primary" :disabled="sending">Editar</md-button>
            </md-card-actions>
          </md-card>
          <br>
          <br>
          <br>
          <br>
        </div>
          <md-button v-if="create"
            @click="create_account()"
            class="md-primary" :disabled="sending">Crear base de cuentas</md-button>
        <md-snackbar
          :md-active.sync="userSaved">La cuenta {{ account }} ha sido actualizada!</md-snackbar>
      </div>
    </form>
    <md-dialog :md-active.sync="showDialog_form">
      <md-dialog-title>Editar cuenta contable</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-65 md-size-75">
            <md-field >
              <label for="name">Descripción</label>
              <md-input
              v-model="instanceForm.name" type="text" :disabled="sending" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-35 md-size-25">
            <br>
            <md-switch v-model="instanceForm.status" :disabled="sending" >
              <span v-if="instanceForm.status">VISIBLE</span>
              <span v-if="!instanceForm.status">OCULTO</span>
            </md-switch>
          </div>
          <div class="md-layout-item md-small-size-65 md-size-75">
            <md-autocomplete autocomplete="off" class="autocomplete"
              :disabled="sending"
              v-model="instanceForm.account_name"
              md-input-name="account"
              :md-options="list_account"
              @md-selected="set_account_edit"
              @md-changed="get_list_account" >
            <label for="account">Cuenta bancaria</label>
            <template
              slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">
                {{ item.name }} ({{ item.account_number }}) {{ item.bank_name }}
              </md-highlight-text>
            </template>
            </md-autocomplete>
          </div>
          <div class="md-layout-item md-small-size-35 md-size-25">
            <br>
            <md-switch v-model="instanceForm.cumulative" :disabled="sending" >
              <span v-if="instanceForm.cumulative">ACUMULATIVA</span>
              <span v-if="!instanceForm.cumulative">DETALLE</span>
            </md-switch>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-50">
            <md-field disabled="disabled">
              <label for="nomenclature">Nomenclatura</label>
              <md-input
              v-model="instanceForm.nomenclature" type="text" :disabled="sending" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-field disabled="disabled">
              <label for="nature">Naturaleza</label>
              <md-input
              v-model="instanceForm.nature" type="text" disabled />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-button @click.prevent="update()"
            class="md-primary" :disabled="sending">Guardar cambios</md-button>
          </div>
        </div>
        <md-progress-bar md-mode="indeterminate" v-if="sending" />
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_form = false">Cerrar</md-button>
      </md-dialog-actions>
      <md-snackbar
        :md-active.sync="accountSaved">
      La cuenta {{ instanceForm.name }} ha sido actualizada!</md-snackbar>
      <md-snackbar
        :md-active.sync="alert">{{ message }}</md-snackbar>
    </md-dialog>
  </div>
</template>
<script>
/* eslint-disable */

import VJstree from 'vue-jstree'

import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  components: {
    VJstree
  },
  name: 'FormCuentasContables',
  mixins: [validationMixin],
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
      info: {},
      instance: {
        name: null,
        cumulative: true,
        father: null,
        nature: null,
      },
      alert: false,
      message: null,
      userSaved: false,
      url: 'accounting_account/',
      url_account_bank: 'account_bank/',
      accountSaved: false,
      sending: false,
      showDialog_form: false,
      account: null,
      datos: false,
      data: [],
      create: true,
      list_parents: [],
      list_account: [],
      instanceForm: {},
      editNode: null,
      editItem: {},
      asyncData: [],
    }
  },
  validations: {
    instance: {
      name: {
        required,
      },
    },
  },
  methods: {
    async loadData (oriNode, resolve) {
      const self = this;
      if (this.sending) return
      this.sending = true
      if (oriNode.data.id) {
        await this.retrieveRequest(this.url,{id: oriNode.data.id} ).then(function (data) {
          resolve(data.data.children)
          self.sending = false
        }).catch(function (error) {
          self.sending = false
          this.handlerError(error)
        })
        return false;
      }
        
      let payload = {}
      payload.company = localStorage.getItem('office')

      this.sendRequest(payload, this.url).then((data) => {
        if (data.data.length === 0){
          self.create = true
        }else{
          self.create = false
        }
        resolve(data.data)
        self.sending = false
      }).catch((error) => {
        self.sending = false
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
    },
    refreshNode: function () {
      this.asyncData = [
          this.$refs.tree.initializeLoading()
      ]
      this.$refs.tree.handleAsyncLoad(this.asyncData, this.$refs.tree)
    },
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    set_account(obj) {  
      this.instance.account_name = obj.name;
      this.instance.account_bank = obj.id;
    },
    set_account_edit(obj) {  
      this.instanceForm.account_name = obj.name;
      this.instanceForm.account_bank = obj.id;
    },
    get_list_account(searchTerm) {
      const payload = {};
      const self = this;
      if (!this.$store.state.company_obj){
        self.message= 'Debe elegir un contribuyente antes.'
        self.alert = true
      }
      payload.company = this.$store.state.company_obj.id

      this.sendRequest( payload, this.url_account_bank ).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      this.$swal.fire('Error!', result, 'error');
      return false;
    },
    create_account() {
      let payload = {}
      const self = this;
      payload.company = localStorage.getItem('office')
      payload.crearCuentas = true
      this.sendRequest(payload, this.url).then((data) => {
        self.data = data.data;
        self.create = false
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    list() {
      let payload = {}
      const self = this;
      payload.company = localStorage.getItem('office')
      
      if (this.sending) return
      this.sending = true
      this.sendRequest(payload, this.url).then((data) => {
        self.sending = false
        self.data = data.data;
        if (self.data.length === 0){
          self.create = true
        }else{
          self.create = false
        }

      }).catch((error) => {
        self.sending = false
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      //this.$v.$reset()
      this.instance = { cumulative: false }
      this.instanceForm = {}
      this.list_parents = []
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    update () {
      const payload = this.instanceForm
      const self = this
      this.sending = true
      if (payload.id) {
        this.putRequest(payload).then(function (data) {
          self.account = `${self.instance.name}`
          self.sending = false
          self.accountSaved = true
          self.instanceForm = data.data
          if (self.instanceForm.account_bank) {
            self.instanceForm.account_name = self.instanceForm.account_bank_obj.full_name
          }
          self.editNode.model = data.data
          self.editNode.model.selected = true
        }).catch(function (error) {
          self.list()
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
    },
    saveOrUpdate () {
      const payload = this.instance
      const self = this
      if (!this.instance.nomenclature) {
        delete payload.nomenclature
      }
      this.sending = true
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.account = `${self.instance.name}`
          self.userSaved = true
          self.sending = false
          self.instance = data.data
          self.list()
          self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.company = localStorage.getItem('office')
      this.postRequest(payload).then(function (data) {
        self.account = `${self.instance.name}`
        self.userSaved = true
        self.sending = false
        self.addChildNode(data.data)
        self.clearForm()
      }).catch(function (error) {
        console.log(error)
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    addChildNode: function (item) {
      if (this.editItem.id !== undefined) {
        item.selected = true
        this.editItem['children'].push(item)
      }
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    get_parent (item) {
      this.list_parents.unshift(item.parent[0].text)
      if (item.parent[0].parent) {
        if (item.parent[0].parent.length === 1) {
          this.get_parent(item.parent[0])
        }
      }
    },
    async itemClick (node) {
      this.editNode = node
      this.editItem = node.model

      this.instance.father = node.model.id
      this.instance.nature = node.model.nature
      this.instance.cumulative = false
      this.instanceForm = Object.assign({}, node.model);
      if (this.instanceForm.account_bank) {
        this.instanceForm.account_name = this.instanceForm.account_bank_obj.full_name
      }

      this.list_parents = []
      let data = await this.retrieveRequest(this.url, {id: node.model.id, fathers: "True"}).catch(function (error) {
        self.handlerError(error)
      })
      this.info = data.data
      this.$refs.descripcion.$el.focus()

      if(this.info.cumulative) {
        this.list_parents.unshift(this.info.text)
      }
      if (this.info.parent) {
        if (this.info.parent.length === 1) {
          this.get_parent(this.info)
        }
      }
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
  .tree-default .tree-selected {
    background: #ff5252 !important;
  }
  .md-card.md-theme-default {
    color: #2193be;
  }
  .tree-default .tree-wholerow-clicked {
    background: #513e3e;
  }

 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  li.tree-wholerow-hovered .tree-hovered{
    background: black;
  }
  li .tree-wholerow-clicked  {
      background: #763636;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
  .md-dialog{
    z-index: 9;
  }
</style>
